<template>
	<div class="banner-list">
		<CustomTableBanner
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			@create="goToCreateForm"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>

			<template #body>
				<template v-if="banners && banners.length">
					<tr
						v-for="(banner, index2) in banners"
						:key="`${banner.id}-${index2}`"
						@click="goToDetail($event, banner.id)"
						@click.right="goToUpdate($event, banner.id)"
						@click.middle="goToUpdate($event, banner.id)"
					>
						<td scope="row" class="table-no">{{ getNo(index2) }}</td>
						<td scope="row" class="text-break text-pre-wrap name">{{ banner.name }}</td>
						<td scope="row" class="text-break text-pre-wrap email">{{
							getBannerTypeLabel(banner.type)
						}}</td>
						<td scope="row" class="text-break text-pre-wrap clinic text-center">
							<img v-lazy="getImageLink(banner)" class="banner-image" />
						</td>
						<td scope="row">
							<div v-if="banner.type != 'TUTORIAL'">
								<span v-if="banner.beginDate">Từ ngày: {{ banner.beginDate }}</span> <br />
								<span v-if="banner.endDate">Đến ngày: {{ banner.endDate }}</span>
							</div>
						</td>
						<td scope="row" class="text-break text-pre-wrap date-created">
							<span v-if="getBannerStatus(banner)" class="banner-active status--active">
								{{ $t("BannerStatus.ACTIVE") }}</span
							>
							<span v-else class="banner-inactive status--inactive"
								>{{ $t("BannerStatus.INACTIVE") }}
							</span>
						</td>
						<td class="text-center"
							><router-link
								:to="{ name: 'UpdateBanner', params: { id: banner.id } }"
								class="link-icon text-info"
								><span class="prefix-input d-inline-block"
									><font-awesome-icon
										:icon="['fas', 'eye']"
										:title="$t('Action.Edit')"
									/> </span></router-link
						></td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableBanner>
	</div>
</template>

<script>
import { USER_ROLE } from "@/shared/plugins/constants"
import { GET_LIST_BANNER } from "../store/action-types"
import { GET_LIST_BANNER_SUCCESS, RESET_FILTERS_BANNER_LIST } from "../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"

const { mapActions, mapMutations } = createNamespacedHelpers("banners")
import { get } from "lodash-es"
import moment from "moment"
import CustomTableBanner from "../../../shared/components/CustomTableBanner"
import { BANNER_TYPE } from "../../../shared/plugins"

export default {
	name: "AdminBannerManagement",

	components: { CustomTableBanner },

	props: {},

	data() {
		return {
			headers: [
				{
					title: this.$t("BannerHeader.No"),
					class: "fit",
				},
				{
					title: this.$t("BannerHeader.Name"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("BannerHeader.Type"),
					class: "text-nowrap type",
				},
				{
					title: this.$t("BannerHeader.Image"),
					class: "text-nowrap image",
				},
				{
					title: this.$t("BannerHeader.Time"),
					class: "text-nowrap time",
				},
				{
					title: this.$t("BannerHeader.Status"),
					class: "text-nowrap status",
				},
				{
					title: this.$t("BannerHeader.Edit"),
					class: "text-nowrap edit",
				},
			],
			baseUrl: process.env.VUE_APP_API_BASE_URL,
		}
	},

	computed: {
		...mapState("banners", ["banners", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
	},

	watch: {},

	created() {},

	beforeDestroy() {
		if (!this.$route.path.includes("banners")) this.RESET_FILTERS_BANNER_LIST()
	},

	methods: {
		get,
		...mapActions({ GET_LIST_BANNER }),
		...mapMutations({ GET_LIST_BANNER_SUCCESS, RESET_FILTERS_BANNER_LIST }),
		search(params) {
			this.currentParam = params
			this.GET_LIST_BANNER(params)
		},
		isAuthorized(admin) {
			if (
				this.currentUser?.role === USER_ROLE.SYSTEM_CLINIC &&
				admin.creator?.role === USER_ROLE.SYSTEM_ADMIN
			)
				return false
			return true
		},

		goToDetail(event, bannerId) {
			if (event.target.localName === "td") {
				this.$router.push({ name: "UpdateBanner", params: { id: bannerId } })
			}
		},

		goToUpdate(event, bannerId) {
			if (event.target.localName === "td") {
				let routeData = this.$router.resolve({
					name: "UpdateBanner",
					params: { id: bannerId },
				})
				window.open(routeData.href, "_blank")
			}
		},
		getNo(no) {
			return no + 1 + (+this.recentFilters.offset || 0)
		},
		goToCreateForm() {
			this.$router.push({ name: "CreateBanner" })
		},
		getImageLink(banner) {
			if (banner.images != null && banner.images.length > 0) {
				return banner.images[0].imageUrl
			}
			return null
		},
		getBannerStatus(banner) {
			if (banner.type == BANNER_TYPE.TUTORIAL) {
				if (banner.status === "ACTIVE") return true
				return false
			}
			let currentDate = moment().tz("Asia/Saigon")
			let begin = moment(banner.beginDate, "DD/MM/YYYY")
			let end = moment(banner.endDate, "DD/MM/YYYY")
			if (
				currentDate.isSameOrAfter(begin, "day") &&
				currentDate.isSameOrBefore(end, "day") &&
				banner.status === "ACTIVE"
			) {
				return true
			}
			return false
		},
		getBannerTypeLabel(value) {
			return {
				[BANNER_TYPE.TUTORIAL]: this.$t("BannerForm.Tutorial"),
				[BANNER_TYPE.CAMPAIGN]: this.$t("BannerForm.Campaign"),
				[BANNER_TYPE.BANNER]: this.$t("BannerForm.Banner"),
			}[value]
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.admin-list {
	table {
	}
}

.banner-image {
	max-width: 80px;

	@media (min-width: $xxs) {
		max-width: 80px;
	}
}

.status {
	&--active {
		color: $color-sea-green;
	}

	&--inactive {
		color: $color-red;
	}
}
</style>
